import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	Divider,
	Spinner,
	Stack,
	TableContainer,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
	Wrap,
	Flex,
	Select,
	Icon,
} from "@chakra-ui/react";
import { setCurStatus, setCurStore, setCurClaimValueOrder, setCurDateFiledOrder } from "../redux/slices/userCase.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserCases, getCustomizedCases } from "../redux/actions/caseActions";
import { getPagiCases } from "../redux/actions/caseActions";
import CaseTableItem from "./CaseTableItem";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const CaseDetails = () => {
	const dispatch = useDispatch();
	const {
		loading,
		error,
		userCases,
		pagiCases,
		pagination: { casesPerPage, currentPage, totalPages },
	} = useSelector((state) => state.userCases);

	// useEffect(() => {
	// 	dispatch(getUserCases());
	// }, [dispatch]);

	const paginationButtonClick = (currentPage) => {
		dispatch(getPagiCases(currentPage));
	};

	const statusChanged = () => {
		const curStatus = document.getElementById("selectStatus").value;
		dispatch(setCurStatus(curStatus));
		dispatch(getCustomizedCases(true));
	};

	const storeChanged = () => {
		const curStore = document.getElementById("selectStore").value;
		dispatch(setCurStore(curStore));
		dispatch(getCustomizedCases(true));
	};

	const claimValueOrderChanged = () => {
		const curOrder = document.getElementById("selectClaimValueOrder").value;
		document.getElementById("selectDateFiledOrder").value = "";
		dispatch(setCurDateFiledOrder(null));
		dispatch(setCurClaimValueOrder(curOrder));
		dispatch(getCustomizedCases(false));
	};

	const dateFiledOrderChanged = () => {
		const curOrder = document.getElementById("selectDateFiledOrder").value;
		document.getElementById("selectClaimValueOrder").value = "";
		dispatch(setCurClaimValueOrder(null));
		dispatch(setCurDateFiledOrder(curOrder));
		dispatch(getCustomizedCases(false));
	};

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			alignItems='center'
			justifyContent='flex-start'
			w={{ base: "96%", md: "97%" }}
			marginTop='1.5em'
			flexDir='column'
			borderRadius='1vw'
			bgColor='white'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex alignItems='center' justifyContent='flex-start' w='100%' minH='50em' flexDir='column' borderRadius='1vw'>
					{/* Row 1 */}
					<Flex
						// bgColor='white'
						w='100%'
						h='5em'
						fontSize='1.2vw'
						fontWeight='bold'
						alignItems='center'
						paddingLeft='1.2vw'
						borderRadius='1vw'
					>
						Case Details
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						<TableContainer>
							<Table variant='simple' h='40em' overflow='auto' size={{ base: "sm", md: "md" }}>
								<Thead>
									<Tr>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Case ID</Th>
										<Th>
											<Select
												id='selectStatus'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='STATUS'
												onChange={statusChanged}
											>
												<option value='Reimbursed'>STATUS (REIMBURSED)</option>
												<option value='Negotiating'>STATUS (NEGOTIATING)</option>
												<option value='Closed'>STATUS (CLOSED)</option>
											</Select>
										</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>
											<Select
												id='selectStore'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='STORE'
												onChange={storeChanged}
											>
												<option value='Vasona Books'>STORE (Vasona Books)</option>
												<option value='iPanda Books'>STORE (iPanda Books)</option>
												<option value='Cornerstone Books'>STORE (Cornerstone Books)</option>
											</Select>
										</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>
											<Select
												id='selectClaimValueOrder'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='CLAIM VALUE'
												onChange={claimValueOrderChanged}
											>
												<option value='maxToMin'>CLAIM VALUE (MAX-MIN)</option>
												<option value='minToMax'>CLAIM VALUE (MIN-MAX)</option>
											</Select>
										</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>
											<Select
												id='selectDateFiledOrder'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='DATE FILED'
												onChange={dateFiledOrderChanged}
											>
												<option value='aToZ'>DATE FILED (A-Z)</option>
												<option value='zToA'>DATE FILED (Z-A)</option>
											</Select>
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{pagiCases.length > 0 &&
										pagiCases.map((pagiCase) => <CaseTableItem key={pagiCase._id} userCase={pagiCase} />)}
								</Tbody>
							</Table>
						</TableContainer>
					</Flex>
					{/* Row 3, Pagination buttons */}
					<Flex w='100%' alignItems='center' justifyContent='center' borderRadius='1vw'>
						<Wrap spacing='1vw' justify='center' p='1vw'>
							<Button id='casePagiLeftArrow' onClick={() => paginationButtonClick(1)} size='sm'>
								<Icon className='nav-items-icon' as={MdKeyboardArrowLeft} boxSize={3} />
							</Button>
							{Array.from(Array(totalPages), (e, i) => {
								return (
									<Button
										colorScheme={currentPage === i + 1 ? "teal" : "gray"}
										key={i}
										onClick={() => paginationButtonClick(i + 1)}
										size='sm'
									>
										{i + 1}
									</Button>
								);
							})}
							<Button id='casePagiRightArrow' onClick={() => paginationButtonClick(totalPages)} size='sm'>
								<Icon className='nav-items-icon' as={MdKeyboardArrowRight} boxSize={3} />
							</Button>
						</Wrap>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default CaseDetails;
