import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { FormControl, Button, Flex } from "@chakra-ui/react";

const SetupForm = () => {
	const stripe = useStripe();
	const elements = useElements();

	const [errorMessage, setErrorMessage] = useState(null);

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return null;
		}

		const { error } = await stripe.confirmSetup({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: "http://localhost:3000/portal/payment?current_page=nav-payment",
			},
		});

		if (error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to your customer (for example, payment
			// details incomplete)
			setErrorMessage(error.message);
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}
	};

	return (
		<FormControl h='35em' w='90%' marginTop='1em'>
			<form onSubmit={handleSubmit}>
				<PaymentElement />
				<Flex alignItems='center' justifyContent='center' marginTop='2vh' marginBottom='2vh'>
					<Button color='white' bgColor='rgba(79, 209, 197, 1)' w='100%' fontSize='md' type='submit' disabled={!stripe}>
						Save
					</Button>
				</Flex>
				{/* Show error message to your customers */}
				{errorMessage && <div>{errorMessage}</div>}
			</form>
		</FormControl>
	);
};

export default SetupForm;
