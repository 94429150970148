import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { SlMagnifier } from "react-icons/sl";

const ClientInboundShipScanTile = () => {
	const transformNumber = (num) => {
		let res;
		if (num < 1000) {
			res = num.toFixed(2);
			if (res.slice(-2, res.length) === "00") {
				res = res.slice(0, -3);
			}
		} else if (num < 1000000) {
			res = (num / 1000).toFixed(2);
			if (res.slice(-2, res.length) === "00") {
				res = res.slice(0, -3);
			}
			res += "K";
		} else {
			res = (num / 1000000).toFixed(2);
			if (res.slice(-2, res.length) === "00") {
				res = res.slice(0, -3);
			}
			res += "M";
		}
		return res;
	};
	// const totalCases = useSelector((state) => state.userCases.totalCases);
	const scannedInboundShipment = 0;
	return (
		<Tooltip label={scannedInboundShipment + " shipments scanned"} bg='rgba(0, 0, 0, 0.7)'>
			<Flex
				className='conclude-tile component-dashboard'
				w={{ base: "45%", md: "45%", lg: "45%" }}
				h={{ base: "5em", md: "10em", lg: "10em" }}
				// bgColor='rgba(248, 131, 121, 1)'
				bgColor='rgba(79, 209, 197, 0.7)'
				alignItems='center'
				justifyContent='center'
			>
				<Flex flexDir='column' alignItems='center' justifyContent='center'>
					{/* Icon */}
					<Icon className='nav-items-icon' flexBasis='5vw' as={SlMagnifier} w='3vw' h='3vw' />
					{/* Description 1*/}
					<Text mt='2vh' fontSize='2vw' fontWeight='bold'>
						{transformNumber(scannedInboundShipment)}
					</Text>
					{/* Description 2*/}
					<Text fontSize='1vw'>Shipments Scanned</Text>
				</Flex>
			</Flex>
		</Tooltip>
	);
};

export default ClientInboundShipScanTile;
