import { useSelector } from "react-redux";
import { Flex, Heading, Box, Text, Icon, Divider, Center, useToast } from "@chakra-ui/react";
import { FiCreditCard } from "react-icons/fi";
import { RiVisaLine } from "react-icons/ri";
import { FaCcMastercard, FaCcDiscover } from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import chipImg from "../images/chip.png";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const PaymentCard = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const toast = useToast();

	useEffect(() => {
		if (searchParams.get("setup_intent_client_secret")) {
			toast({
				description: "Your card was successfully saved.",
				status: "success",
				isClosable: true,
			});
		}
	}, [searchParams, toast]);

	const { stripeIntentSecret, stripeCustomerSessionClientSecret, stripeCreditCardInfo } = useSelector(
		(state) => state.user
	);
	return (
		<Box
			className='component-dashboard'
			borderRadius='1vw'
			borderWidth='0.1vmax'
			w={{ base: "250px", md: "300px" }}
			h={{ base: "180px", md: "200px" }}
			bgGradient='linear(to-t, #0BC5EA, #4FD1C5)'
		>
			<Flex p='1em' color='#fff' flexDir='column' h='100%' justify='space-between'>
				<Flex justify='space-between' w='100%' align='flex-start'>
					<Flex flexDir='column'>
						<Text color='white' fontWeight='bold' fontSize='md'>
							My Card
						</Text>
					</Flex>
				</Flex>
				<Flex marginLeft='0.5vw' marginTop='2vh'>
					<img src={chipImg} width='30vw' />
				</Flex>
				<Text mb={4}>**** **** **** {stripeCreditCardInfo.lastFour}</Text>

				<Flex align='flex-end' justify='space-between'>
					<Flex>
						<Flex flexDir='column' mr={8}>
							<Text textTransform='uppercase' fontSize='1.5vmin'>
								Valid Thru
							</Text>
							<Text fontSize='md'>
								{stripeCreditCardInfo.expMonth}/{stripeCreditCardInfo.expYear}
							</Text>
						</Flex>
						<Flex flexDir='column'>
							<Text textTransform='uppercase' fontSize='1.5vmin'>
								CVC
							</Text>
							<Text fontSize='md'>***</Text>
						</Flex>
					</Flex>
					<Flex>
						{stripeCreditCardInfo.brand.toLowerCase() === "visa" ? (
							<Icon as={RiVisaLine} color='white' boxSize={10} />
						) : stripeCreditCardInfo.brand.toLowerCase() === "mastercard" ? (
							<Icon as={FaCcMastercard} color='white' boxSize={10} />
						) : stripeCreditCardInfo.brand.toLowerCase() === "discover" ? (
							<Icon as={FaCcDiscover} color='white' boxSize={10} />
						) : stripeCreditCardInfo.brand.toLowerCase() === "amex" ? (
							<Icon as={SiAmericanexpress} color='white' boxSize={10} />
						) : (
							<Icon as={FiCreditCard} />
						)}
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};

export default PaymentCard;
