import axios from 'axios';
import {
    setShipNeedDoc,
    setEventError,
    setPagiEvents,
    setPagination
} from '../slices/event.js';

export const getShipWaitDoc = (userId) => async (dispatch, getState) => {
    const { pagination: { eventsPerPage, currentPage, totalPages } } = getState().event;
    try {
        const config = { headers: { 'Content-Type': 'application/json' }, params: { 'userId': userId } };
        const { data } = await axios.get(`/api/portal/inbound`, config);
        dispatch(setShipNeedDoc(data));
        if (data.length > 0) {
            const newTotalPages = Math.ceil(data.length / eventsPerPage);
            const startIndex = (currentPage - 1) * eventsPerPage;
            const endIndex = startIndex + eventsPerPage;
            const paginatedEvents = data.slice(startIndex, endIndex);
            dispatch(setPagination({ eventsPerPage, currentPage, 'totalPages': newTotalPages }));
            dispatch(setPagiEvents(paginatedEvents));
        }
    } catch (error) {
        dispatch(
            setEventError(
                error.response && error.response.data
                    ? error.response.data
                    : error.message
                        ? error.message
                        : 'An unexpected error has occured. Please try again later.'
            )
        );
    }
}

export const getPagiEvents = (clickedPage) => async (dispatch, getState) => {
    const curEvents = getState().event.workingEvents;
    const { eventsPerPage, totalPages } = getState().event.pagination;

    const startIndex = (clickedPage - 1) * eventsPerPage;
    const endIndex = startIndex + eventsPerPage;
    const paginatedEvents = curEvents.slice(startIndex, endIndex);
    dispatch(setPagination({ eventsPerPage, 'currentPage': clickedPage, totalPages }));
    dispatch(setPagiEvents(paginatedEvents));
}