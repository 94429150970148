import "../Styles/landingScreen.css";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const ClientCredit = () => {
	const { credit, referralId } = useSelector((state) => state.user.userInfo);

	return (
		<Flex w='85%' minH='100vh' flexDir={{ base: "column", md: "row" }}>
			{/* Left Part */}
			<Flex w={{ base: "100%", md: "50%" }} h={{ base: "30%", md: "100%" }} justifyContent='center' alignItems='center'>
				<Text fontSize={{ base: "1em", md: "1em", lg: "1.2em" }}>
					Woohooooo! You have{" "}
					<Text as='span' color='#4FD1C5'>
						${credit}
					</Text>{" "}
					credits
				</Text>
			</Flex>
			{/* Right Part */}
			<Flex
				className='landing-body'
				w={{ base: "100%", md: "50%" }}
				h={{ base: "70%", md: "100%" }}
				flexDir='column'
				alignItems='center'
				justifyContent='center'
				p='2em'
			>
				<Heading color='white' fontSize={{ base: "1.5em", md: "2em" }}>
					Want more credits?
				</Heading>
				<Text marginTop='1em' marginBottom='1em' color='white' fontSize={{ base: "0.8em", md: "1em" }}>
					Refer us a client, and you receive $100 statement credits.
				</Text>
				<Text color='white' fontSize={{ base: "0.8em", md: "1em" }}>
					Have your friend enter your referral code upon adding their store following the signup. The credits will then
					automatically be applied in your next month statement.
				</Text>
				<Text marginBottom='1em' fontSize={{ base: "0.8em", md: "1em" }}></Text>
				<Text color='white' fontSize={{ base: "0.8em", md: "1em" }}>
					Your referral code:{" "}
					<Text as='span' fontWeight='bold' fontSize={{ base: "0.8em", md: "1em" }}>
						{referralId}
					</Text>
				</Text>
			</Flex>
		</Flex>
	);
};

export default ClientCredit;
