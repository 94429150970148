import {
    setLoading,
    setUserCases,
    setPagiCases,
    setError,
    setPagination,
    setIndexedCases,
    setWorkingCases,
    setBarChartReimbData,
    setBarChartCaseData,
    setDoughnutChartReimbData,
    setDoughnutChartTypeData,
    setTotalCases,
    setTotalNegotiating,
    setTotalReimbursed
} from '../slices/userCase.js';
import axios from 'axios';


export const getUserCases = (userId) => async (dispatch, getState) => {
    const { pagination: { casesPerPage, currentPage, totalPages } } = getState().userCases;
    dispatch(setLoading());

    async function createIndexedCases(userCases) {
        const indexedCases = {};
        // Initialize the bar chart's data
        const curMonth = new Date().getMonth();
        const monthToIndexOffset = 11 - curMonth;
        const monthToIndexMapping = {};
        for (let i = 0; i < 12; i++) {
            monthToIndexMapping[i] = (i + monthToIndexOffset) % 12;
        };
        const curYear = new Date().getFullYear();
        // Calculate the bar chart's label
        const labels = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const barChartLabels = ["", "", "", "", "", "", "", "", "", "", "", ""];
        for (let i = 0; i < 12; i++) {
            barChartLabels[monthToIndexMapping[i]] = labels[i];
        };

        // Initialize background colors and border colors for charts.
        const bgColors = [
            "rgba(255, 99, 132, 1)",
            "rgba(11, 197, 234, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(79, 209, 197, 1)",
            "rgba(95, 158, 160, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(165, 42, 42, 1)",
            "rgba(127, 255, 0, 1)",
        ];
        const borderColors = [
            "rgba(255, 99, 132, 1)",
            "rgba(11, 197, 234, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(79, 209, 197, 1)",
            "rgba(95, 158, 160, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(165, 42, 42, 1)",
            "rgba(127, 255, 0, 1)",
        ];
        // Eventually we will get this data from state.user
        // Currently, we don't support more than 10 stores under the same account.

        const storeNames = ['Vasona Books', 'Cornerstone Books', 'iPanda Books']
        const storeNamesMapping = { 'Vasona Books': 0, 'Cornerstone Books': 1, 'iPanda Books': 2 };
        const storeNamesLength = Object.keys(storeNames).length;
        if (storeNamesLength > 10) {
            console.log('The account has more than ten stores!');
            return;
        };
        // Initialize reimbursement bar chart data.
        const barReimbData = [];
        let i = 0;
        while (i < storeNamesLength) {
            barReimbData.push({
                label: storeNames[i],
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                backgroundColor: bgColors[i],
            })
            i += 1;
        };
        // Initialize case bar chart data.
        const barCaseData = [];
        i = 0;
        while (i < storeNamesLength) {
            barCaseData.push({
                label: storeNames[i],
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                backgroundColor: bgColors[i],
            })
            i += 1;
        };
        // Initialize reimbursement doughnutChart data.
        const doughnutStoreData = {
            labels: storeNames,
            datasets: [
                {
                    label: "Reimbursed Value",
                    data: [...Array(storeNamesLength)].fill(0),
                    backgroundColor: bgColors.slice(0, storeNamesLength),
                    borderColor: borderColors.slice(0, storeNamesLength),
                    borderWidth: 1,
                },
            ],
        };
        // Initialize reimbursementType doughnutChart data.
        const reimbTypes = ['inbound', 'lost', 'damaged', 'outbound', 'customerReturn', 'other'];
        const reimbTypesMapping = { 'inbound': 0, 'lost': 1, 'damaged': 2, 'outbound': 3, 'customerReturn': 4, 'other': 5 };
        const doughnutTypeData = {
            labels: reimbTypes,
            datasets: [
                {
                    label: "Reimbursed Value",
                    data: [...Array(6)].fill(0),
                    backgroundColor: bgColors.slice(0, 6),
                    borderColor: borderColors.slice(0, 6),
                    borderWidth: 1,
                },
            ],
        };

        // Initialize 3 variables for keeping track of totalCases, totalReimbursed and totalNegotiating.
        let totalCases = { 'total': userCases.length, 'detail': { 'inbound': 0, 'lost': 0, 'damaged': 0, 'outbound': 0, 'customerReturn': 0, 'other': 0 } };
        let totalReimbursed = { 'total': 0, 'detail': { 'inbound': 0, 'lost': 0, 'damaged': 0, 'outbound': 0, 'customerReturn': 0, 'other': 0 } };
        let totalNegotiating = { 'total': 0, 'detail': { 'inbound': 0, 'lost': 0, 'damaged': 0, 'outbound': 0, 'customerReturn': 0, 'other': 0 } };

        // Calculate charts' data, and indexing cases for easy indexing.
        for (let i = 0; i < userCases.length; i++) {
            const obj = userCases[i];
            //update totalCases, totalReimbursed, totalNegotiating
            const caseType = obj.caseType;
            // Report the input data error.
            if (!(caseType in reimbTypesMapping)) {
                console.log('Error! the record\'s reimbursement type not in preset type collections!');
                console.log(`Error input reimbursement type: ${caseType}`);
                continue;
            }
            //
            totalCases['detail'][caseType] += 1;
            const value = obj.claimValue;
            const caseStatus = obj.status;
            // setup indexedCases
            const caseStore = obj.storeName;

            const keys = [`_${caseStatus}`, `${caseStore}_`, `${caseStore}_${caseStatus}`];
            for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                if (!(key in indexedCases)) {
                    indexedCases[key] = [];
                }
                indexedCases[key].push(obj);
            }
            // Collect data for reimbursement store doughnut chart and reimbursementType doughnut chart .
            if (obj.status === 'Reimbursed') {
                totalReimbursed['total'] += value;
                totalReimbursed['detail'][caseType] += value;
                doughnutStoreData.datasets[0].data[storeNamesMapping[obj.storeName]] += obj.claimValue;
                doughnutTypeData.datasets[0].data[reimbTypesMapping[caseType]] += obj.claimValue;
            } else if (obj.status === 'Negotiating') {
                totalNegotiating['total'] += value;
                totalNegotiating['detail'][caseType] += value;
            }
            // Charts using only data from the past year.
            if ((obj.yearFiled < curYear - 1) || (obj.yearFiled === curYear - 1 && obj.monthFiled <= curMonth)) continue;
            // Record data for the case bar chart.
            barCaseData[storeNamesMapping[obj.storeName]].data[monthToIndexMapping[obj.monthFiled]] += 1;
            // Record reimbursement records for reimbursement bar chart. 
            if (obj.status === 'Reimbursed') {
                barReimbData[storeNamesMapping[obj.storeName]].data[monthToIndexMapping[obj.monthFiled]] += obj.claimValue;
            }
        };
        dispatch(setTotalCases(totalCases));
        dispatch(setTotalReimbursed(totalReimbursed));
        dispatch(setTotalNegotiating(totalNegotiating));
        dispatch(setBarChartReimbData({ barChartLabels, barReimbData }));
        dispatch(setBarChartCaseData({ barChartLabels, barCaseData }));
        dispatch(setDoughnutChartReimbData(doughnutStoreData));
        dispatch(setDoughnutChartTypeData(doughnutTypeData));
        dispatch(setIndexedCases(indexedCases));

    };


    try {
        const config = { headers: { 'Content-Type': 'application/json' }, params: { 'userId': userId } };
        const { data } = await axios.get(`/api/portal/case`, config);
        dispatch(setUserCases(data));
        if (data.length > 0) {
            const newTotalPages = Math.ceil(data.length / casesPerPage);
            const startIndex = (currentPage - 1) * casesPerPage;
            const endIndex = startIndex + casesPerPage;
            const paginatedCases = data.slice(startIndex, endIndex);
            dispatch(setPagination({ casesPerPage, currentPage, 'totalPages': newTotalPages }));
            dispatch(setPagiCases(paginatedCases));
            createIndexedCases(data);
        }
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data
                    ? error.response.data
                    : error.message
                        ? error.message
                        : 'An unexpected error has occured. Please try again later.'
            )
        );
    }
};


export const getCustomizedCases = (isWorkingCasesChanged) => async (dispatch, getState) => {
    if (getState().userCases.userCases.length === 0) return;
    const { casesPerPage } = getState().userCases.pagination;
    let { totalPages } = getState().userCases.pagination;
    const { curClaimValueOrder, curDateFiledOrder } = getState().userCases;

    let curCases = getState().userCases.workingCases;
    if (isWorkingCasesChanged) {
        const curStatus = getState().userCases.curStatus;
        const curStore = getState().userCases.curStore;
        const key = `${curStore}_${curStatus}`;
        curCases = [];
        if (key === '_') {
            curCases = getState().userCases.userCases;
        } else if (key in getState().userCases.indexedCases) {
            curCases = getState().userCases.indexedCases[key];
        }
        totalPages = Math.ceil(curCases.length / casesPerPage);
    };
    // console.log(curCases);
    if (curClaimValueOrder) {
        curCases = [...curCases];
        if (curClaimValueOrder === 'maxToMin') {
            curCases.sort((element1, element2) => (element2['claimValue'] - element1['claimValue']))
        } else {
            curCases.sort((element1, element2) => (element1['claimValue'] - element2['claimValue']))
        }
    } else if (curDateFiledOrder) {
        curCases = [...curCases];
        if (curDateFiledOrder === 'zToA') {
            curCases.sort((element1, element2) => {
                if (element1.dateFiled < element2.dateFiled) return 1;
                return -1;
            })
        } else {
            curCases.sort((element1, element2) => {
                if (element1.dateFiled < element2.dateFiled) return -1;
                return 1;
            })
        }
    }
    const paginatedCases = curCases.slice(0, casesPerPage);

    dispatch(setWorkingCases(curCases));
    dispatch(setPagination({ casesPerPage, 'currentPage': 1, totalPages }));
    dispatch(setPagiCases(paginatedCases));
}

export const getPagiCases = (clickedPage) => async (dispatch, getState) => {
    const curCases = getState().userCases.workingCases;
    const { casesPerPage, totalPages } = getState().userCases.pagination;

    const startIndex = (clickedPage - 1) * casesPerPage;
    const endIndex = startIndex + casesPerPage;
    const paginatedCases = curCases.slice(startIndex, endIndex);
    dispatch(setPagination({ casesPerPage, 'currentPage': clickedPage, totalPages }));
    dispatch(setPagiCases(paginatedCases));

}
