import { Flex, Text } from "@chakra-ui/react";

const EmptyNotice = () => {
	return (
		<Flex w='100%' h='100%' flexDir='column' justifyContent='center' alignItems='center'>
			<Text fontSize='1.5vmax'>You have no saved card</Text>
			<Text fontSize='1vmax'>No worry. You can add a payment method using the form on this page.</Text>
		</Flex>
	);
};

export default EmptyNotice;
