import "../Styles/globals.css";
import "../Styles/landingScreen.css";
import { Flex, Heading, Button, List, ListItem, Text, Spacer, Icon } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { CiFileOn } from "react-icons/ci";
import { LiaShippingFastSolid } from "react-icons/lia";
import { LiaWarehouseSolid } from "react-icons/lia";
import { LiaHouseDamageSolid } from "react-icons/lia";
import { TbTruckReturn } from "react-icons/tb";
import { TbHomeMove } from "react-icons/tb";
import { SiThealgorithms } from "react-icons/si";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { RiUserHeartLine } from "react-icons/ri";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { LuHeading3 } from "react-icons/lu";

const LandingScreen = () => (
	// The viewport flexbox. bgColor='rgba(242, 242, 242, 0.7)'
	<div>
		<Flex position='fixed' w='100%' h='10vmin'>
			<MainHeader />
		</Flex>

		<Flex
			className='landing-body'
			w='100%'
			minH='100vh'
			paddingTop={{ base: "1em", md: "1em", lg: "2em" }}
			paddingLeft={{ base: "1em", md: "1em", lg: "2em" }}
			paddingRight={{ base: "1em", md: "1em", lg: "2em" }}
			paddingBottom={{ base: "1em", md: "1em", lg: "1em" }}
			flexDir={{ base: "column", md: "row", lg: "row" }}
		>
			<Flex
				w={{ base: "100%", md: "40%", lg: "40%" }}
				h={{ base: "26em", md: "100vh", lg: "100vh" }}
				paddingTop={{ base: "1em", md: "1em", lg: "2em" }}
				paddingLeft={{ base: "1em", md: "1em", lg: "2em" }}
				paddingRight={{ base: "1em", md: "1em", lg: "2em" }}
				// paddingBottom={{ base: "1em", md: "1em", lg: "1em" }}
				alignItems='center'
			>
				<Heading
					className='landing-slogan'
					fontSize={{ base: "3.5em", md: "4.3em", lg: "4.3em" }}
					letterSpacing='tight'
					color='white'
				>
					Ready to increase your cash flow?
				</Heading>
			</Flex>
			<Flex
				w={{ base: "100%", md: "60%", lg: "60%" }}
				h={{ base: "5em", md: "100vh", lg: "100vh" }}
				flexDir='column'
				justifyContent={{ base: "flex-start", md: "center", lg: "center" }}
				alignItems='center'
				paddingLeft={{ base: "1em", md: "1em", lg: "2em" }}
			>
				<Flex
					className='landing-subtitle'
					color='white'
					// h={{ base: "1.25em", md: "1.5em", lg: "1.75em" }}
					flexDir={{ base: "column", lg: "row" }}
				>
					<Text fontSize={{ base: "1em", md: "1em", lg: "1.25em" }}>It is our mission to</Text>
					<Flex w='0.5vmax'></Flex>
					<Flex h={{ base: "1.5em", md: "1.5em", lg: "1.75em" }}>
						<List className='slogan-list' fontSize={{ base: "1em", md: "1em", lg: "1.25em" }}>
							<ListItem className='slogan-list-item'>maximize your FBA reimbursement.</ListItem>
							<ListItem className='slogan-list-item'>boost your cash flow.</ListItem>
							<ListItem className='slogan-list-item'>make you smile.</ListItem>
						</List>
					</Flex>
				</Flex>

				{/* <Flex h='10%'></Flex>
				<Flex color='white' letterSpacing='tight'>
					<Button
						colorScheme='white'
						variant='outline'
						borderRadius='0'
						borderWidth='0.2vmax'
						size={{ base: "md", md: "md", lg: "lg" }}
					>
						Request a free trial
					</Button>
				</Flex> */}
			</Flex>
		</Flex>

		{/* Second part: why reimbursement, what's the problem, what we do can solve this need. */}
		<Flex
			w='100%'
			minH='100vh'
			paddingTop={{ base: "5em", md: "1em", lg: "5em" }}
			paddingLeft={{ base: "1em", md: "1em", lg: "3em" }}
			paddingRight={{ base: "1em", md: "1em", lg: "3em" }}
			paddingBottom={{ base: "5em", md: "1em", lg: "5em" }}
			flexDir='column'
			justifyContent='center'
			bgColor='black'
		>
			<Text color='white' fontSize={{ base: "1.5em", md: "2em", lg: "2em" }} fontWeight='bold' textAlign='center'>
				Maximize FBA Reimbursement?
			</Text>
			<Text color='white' fontSize={{ base: "1.5em", md: "2em", lg: "2em" }} fontWeight='bold' textAlign='center'>
				Count On Us
			</Text>
			<Flex h='5vh' />
			<Text color='white'>Amazon owns you money, and we can help you get your money back!</Text>
			<Text color='white'>
				Amazon can own a FBA seller thousands of dollars per month due to various reasons, such as shipping lost,
				warehouse lost or damaged, refunded merchandise never returned, etc..
			</Text>
			<Text color='white'>
				Manually auditing all these issues and filing cases to Amazon customer service is time-consuming and
				soul-destroying.
			</Text>
			<Text color='white'>
				Urgently, Amazon has announced: &nbsp;
				<Text as='span' fontWeight='bold' fontStyle='italic'>
					starting from November 1, 2024, the reimbursement window reduced from 18 months to 2 months. &nbsp;
				</Text>
				This means auditing and filing cases becomes more time-sensitive and stressful.
			</Text>
			<Text color='white'>
				Don't panic! TinkerBoost is here to help! Our service is backed by advanced algorithms developed in our lab and
				can automate the auditing and case-filing process. With our sophisticated algorithms, TinkerBoost is able to
				catch all reimbursable events in your database and maximize your FBA reimbursement.
			</Text>
		</Flex>

		{/* Third part: what type of reimbursement we are tracking. */}
		<Flex
			w='100%'
			minH='100vh'
			paddingTop={{ base: "5em", md: "1em", lg: "5em" }}
			paddingLeft={{ base: "1em", md: "1em", lg: "1.25em" }}
			paddingRight={{ base: "1em", md: "3em", lg: "1.25em" }}
			paddingBottom={{ base: "5em", md: "1em", lg: "5em" }}
			flexDir='column'
			alignItems='center'
			justifyContent='center'
			bgImage='linear-gradient(135deg, #4FD1C5, #64b3f4)'
		>
			<Flex w='100%' alignItems='center' justifyContent='center' marginBottom='5vh'>
				<Text color='white' fontSize={{ base: "1.5em", md: "2em", lg: "2em" }} fontWeight='bold'>
					What We Audit
				</Text>
			</Flex>
			<Flex
				w='100%'
				justifyContent='space-around'
				alignItems='center'
				padding={{ base: "2vh", md: "2vh", lg: "2vh" }}
				flexDir={{ base: "column", md: "column", lg: "column" }}
			>
				{/* 4 tiles */}
				<Flex
					w='100%'
					justifyContent='space-around'
					alignItems='center'
					flexDir={{ base: "column", md: "row", lg: "row" }}
				>
					<Flex
						w={{ base: "100%", md: "50%", lg: "50%" }}
						h={{ base: "20em", md: "20em", lg: "20em" }}
						flexDir='column'
						padding='1em'
						borderRadius='2vw'
						borderWidth='0.1vw'
						borderColor='white'
						justifyContent='center'
						alignItems='center'
						margin={{ base: "1em", md: "1em", lg: "2em" }}
					>
						<Flex alignItems='center' flexDir='column' marginTop={{ base: "2em", md: "2em", lg: "2em" }}>
							<Icon
								className='nav-items-icon'
								boxSize={{ base: "6em", md: "6em", lg: "6em" }}
								as={LiaShippingFastSolid}
								color='white'
								marginBottom='3em'
							/>
						</Flex>
						<Flex alignItems='center' flexDir='column'>
							<Text
								color='white'
								textAlign='center'
								fontSize={{ base: "1.25em", md: "1.25em", lg: "1.25em" }}
								fontWeight='bold'
							>
								Inbound Shipping
							</Text>
							<Text
								color='white'
								alignItems='center'
								justifyContent='center'
								fontSize={{ base: "1em", md: "1em", lg: "1em" }}
								marginTop='1em'
							>
								Items can get lost on their way to the FBA warehouse. You sent 100, and FBA warehouse only received 90.
								We track all your inbound shipments and catch all discrepancies in your shipments.
							</Text>
						</Flex>
					</Flex>

					<Flex
						w={{ base: "100%", md: "50%", lg: "50%" }}
						h={{ base: "20em", md: "20em", lg: "20em" }}
						flexDir='column'
						padding='1em'
						borderRadius='2vw'
						borderWidth='0.1vw'
						borderColor='white'
						justifyContent='center'
						alignItems='center'
						margin={{ base: "1em", md: "1em", lg: "2em" }}
					>
						<Flex alignItems='center' flexDir='column' marginTop={{ base: "2em", md: "2em", lg: "2em" }}>
							<Icon
								className='nav-items-icon'
								boxSize={{ base: "6em", md: "6em", lg: "6em" }}
								as={LiaHouseDamageSolid}
								color='white'
								marginBottom='3em'
							/>
						</Flex>
						<Flex alignItems='center' justifyContent='center' flexDir='column'>
							<Text
								color='white'
								textAlign='center'
								fontSize={{ base: "1.25em", md: "1.25em", lg: "1.25em" }}
								fontWeight='bold'
							>
								Warehouse Lost/ Damaged
							</Text>
							<Text
								color='white'
								alignItems='center'
								justifyContent='center'
								fontSize={{ base: "1em", md: "1em", lg: "1em" }}
								marginTop='1em'
							>
								FBA warehouse holds a huge amount of merchandises, and your merchandises get lost or damaged in their
								warehouse every now and then. We monitor all these events happened in the FBA warehouse for you.
							</Text>
						</Flex>
					</Flex>
				</Flex>

				<Flex
					w='100%'
					justifyContent='space-around'
					alignItems='center'
					flexDir={{ base: "column", md: "row", lg: "row" }}
				>
					<Flex
						w={{ base: "100%", md: "50%", lg: "50%" }}
						h={{ base: "20em", md: "20em", lg: "20em" }}
						flexDir='column'
						padding='1em'
						borderRadius='2vw'
						borderWidth='0.1vw'
						borderColor='white'
						justifyContent='center'
						alignItems='center'
						margin={{ base: "1em", md: "1em", lg: "2em" }}
					>
						<Flex alignItems='center' flexDir='column'>
							<Icon
								className='nav-items-icon'
								boxSize={{ base: "6em", md: "6em", lg: "6em" }}
								as={TbTruckReturn}
								color='white'
								marginBottom='3em'
							/>
						</Flex>
						<Flex alignItems='center' justifyContent='center' flexDir='column'>
							<Text
								color='white'
								textAlign='center'
								fontSize={{ base: "1.25em", md: "1.25em", lg: "1.25em" }}
								fontWeight='bold'
							>
								Customer Return
							</Text>
							<Text
								color='white'
								alignItems='center'
								justifyContent='center'
								fontSize={{ base: "1em", md: "1em", lg: "1em" }}
								marginTop='1em'
							>
								You refund the customer and your item never get returned to the FBA warehouse. We capture and report all
								these items.
							</Text>
						</Flex>
					</Flex>

					<Flex
						w={{ base: "100%", md: "50%", lg: "50%" }}
						h={{ base: "20em", md: "20em", lg: "20em" }}
						flexDir='column'
						padding='1em'
						borderRadius='2vw'
						borderWidth='0.1vw'
						borderColor='white'
						justifyContent='center'
						alignItems='center'
						margin={{ base: "1em", md: "1em", lg: "2em" }}
					>
						<Flex alignItems='center' flexDir='column'>
							<Icon
								className='nav-items-icon'
								boxSize={{ base: "6em", md: "6em", lg: "6em" }}
								as={TbHomeMove}
								color='white'
								marginBottom='3em'
							/>
						</Flex>
						<Flex alignItems='center' justifyContent='center' flexDir='column'>
							<Text
								color='white'
								textAlign='center'
								fontSize={{ base: "1.25em", md: "1.25em", lg: "1.25em" }}
								fontWeight='bold'
							>
								Removal Request Shipping
							</Text>
							<Text
								color='white'
								alignItems='center'
								justifyContent='center'
								fontSize={{ base: "1em", md: "1em", lg: "1em" }}
								marginTop='1em'
							>
								Your removed items get lost on their way back to you. We have you covered and won't miss any of these
								lost shipments.
							</Text>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>

		{/* Fourth part: Why us */}
		<Flex
			w='100%'
			minH='100vh'
			paddingTop={{ base: "1em", md: "1em", lg: "2em" }}
			paddingLeft={{ base: "1em", md: "1em", lg: "1.25em" }}
			paddingRight={{ base: "1em", md: "1em", lg: "1.25em" }}
			paddingBottom={{ base: "3em", md: "1em", lg: "1em" }}
			flexDir={{ base: "column", md: "column", lg: "row" }}
			alignItems='center'
			justifyContent='center'
			bgColor='black'
		>
			<Flex
				w={{ base: "100%", md: "100%", lg: "40%" }}
				h={{ base: "20vh", md: "100vh", lg: "100vh" }}
				alignItems='center'
				justifyContent='center'
			>
				<Text color='white' fontSize={{ base: "1.5em", md: "2em", lg: "3em" }} fontWeight='bold'>
					Why Us
				</Text>
			</Flex>
			<Flex
				w={{ base: "100%", md: "100%", lg: "60%" }}
				minH={{ base: "130vh", md: "100vh", lg: "100vh" }}
				flexDir='column'
				justifyContent='space-around'
			>
				<Flex w='100%' h='30%' alignItems='center' flexDir={{ base: "column", md: "column", lg: "row" }}>
					<Icon
						className='nav-items-icon'
						as={SiThealgorithms}
						boxSize={{ base: "6em", md: "5em", lg: "4em" }}
						margin='3em'
						color='white'
					/>
					<Flex flexDir='column' justifyContent='center' alignItems={{ base: "center", lg: "flex-start" }}>
						<Text
							color='white'
							alignItems='center'
							justifyContent='center'
							fontSize={{ base: "1.25em", md: "1em", lg: "1.25em" }}
							fontWeight='bold'
						>
							Maximized Reimbursement
						</Text>
						<Text color='white' fontSize={{ base: "1em", md: "0.8em", lg: "1em" }}>
							Our advanced algorithms find on average 27% more reimbursable values when comparing with our competitors.
							We are the true Amazon FBA reimbursement maximizer.
						</Text>
					</Flex>
				</Flex>

				<Flex w='100%' h='30%' alignItems='center' flexDir={{ base: "column", md: "column", lg: "row" }}>
					<Icon
						className='nav-items-icon'
						as={AiOutlineThunderbolt}
						boxSize={{ base: "6em", md: "5em", lg: "4em" }}
						margin='3em'
						color='white'
					/>
					<Flex flexDir='column' justifyContent='center' alignItems={{ base: "center", lg: "flex-start" }}>
						<Text
							color='white'
							alignItems='center'
							justifyContent='center'
							fontSize={{ base: "1.25em", md: "1em", lg: "1.25em" }}
							fontWeight='bold'
						>
							Lightning Case-filing
						</Text>
						<Text color='white' fontSize={{ base: "1em", md: "0.8em", lg: "1em" }}>
							Our software constantly monitors your account. For all found issues, we file cases for you in less than 1
							business day.
						</Text>
					</Flex>
				</Flex>

				<Flex w='100%' h='30%' alignItems='center' flexDir={{ base: "column", md: "column", lg: "row" }}>
					<Icon
						className='nav-items-icon'
						as={RiUserHeartLine}
						boxSize={{ base: "6em", md: "5em", lg: "4em" }}
						margin='3em'
						color='white'
					/>
					<Flex flexDir='column' justifyContent='center' alignItems={{ base: "center", lg: "flex-start" }}>
						<Text
							color='white'
							alignItems='center'
							justifyContent={{ base: "center" }}
							fontSize={{ base: "1.25em", md: "1em", lg: "1.25em" }}
							fontWeight='bold'
						>
							Customer Centric
						</Text>
						<Text color='white' fontSize={{ base: "1em", md: "0.8em", lg: "1em" }}>
							Serving customers is our truely first-class priority. We focus on providing the best in class customer
							service.
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</Flex>

		{/* Fifth part: What our customer say */}
		<Flex
			className='landing-body'
			w='100%'
			minH='100vh'
			paddingTop={{ base: "5em", md: "1em", lg: "5em" }}
			paddingLeft={{ base: "1em", md: "1em", lg: "1.25em" }}
			paddingRight={{ base: "1em", md: "3em", lg: "1.25em" }}
			paddingBottom={{ base: "5em", md: "3em", lg: "5em" }}
			flexDir='column'
			alignItems='center'
			justifyContent='center'
		>
			<Flex w='100%' alignItems='center' justifyContent='center'>
				<Heading color='white' textAlign='center'>
					What our customers say
				</Heading>
			</Flex>
			<Flex
				w='100%'
				justifyContent='space-around'
				alignItems='center'
				padding={{ base: "2vh", md: "2vh", lg: "2vh" }}
				flexDir={{ base: "column", md: "column", lg: "row" }}
			>
				<Flex
					w={{ base: "100%", md: "100%", lg: "30%" }}
					h={{ base: "20em", md: "50vh", lg: "20em" }}
					flexDir='column'
					padding='1em'
					borderRadius='2vw'
					borderWidth='0.1vw'
					borderColor='white'
					margin={{ base: "1em", md: "1em", lg: "2em" }}
				>
					<Text color='white'>
						They offers the best service in the FBA reimbursement industry, in my opinion. I used another prominent
						reimbursement service provider in the past before shifting to them. TinkerBoost finds me more money every
						month! Cheers!
					</Text>
					<Text>&nbsp;</Text>
					<Text color='white'>- iPanda Education</Text>
				</Flex>

				<Flex
					w={{ base: "100%", md: "100%", lg: "30%" }}
					h={{ base: "20em", md: "50vh", lg: "20em" }}
					flexDir='column'
					padding='1em'
					borderRadius='2vw'
					borderWidth='0.1vw'
					borderColor='white'
					margin={{ base: "1em", md: "1em", lg: "2em" }}
				>
					<Text color='white'>
						TinkerBoost is very easy to use, and helped us to generate more reimbursement founds than when using another
						service provider. Also, great customer support team always there to answer questions.
					</Text>
					<Text>&nbsp;</Text>
					<Text color='white'>- Pine and Linen</Text>
				</Flex>

				<Flex
					w={{ base: "100%", md: "100%", lg: "30%" }}
					h={{ base: "20em", md: "50vh", lg: "20em" }}
					flexDir='column'
					padding='1em'
					borderRadius='2vw'
					borderWidth='0.1vw'
					borderColor='white'
					margin={{ base: "1em", md: "1em", lg: "2em" }}
				>
					<Text color='white'>
						Tinkerboost is not only a quick acting and effective Amazon reimbursement service, their advising team is
						extremely knowledgeable and reliable as well. They guided us through with optimal options to maximize our
						refund from Amazon.
					</Text>
					<Text>&nbsp;</Text>
					<Text color='white'>- Cornerstone Books</Text>
				</Flex>
			</Flex>
		</Flex>

		{/* Sixth part: Let us help you. Contact us */}
		<Flex
			id='support'
			w='100%'
			minH='100vh'
			paddingTop={{ base: "5em", md: "1em", lg: "1em" }}
			paddingLeft={{ base: "1em", md: "1em", lg: "1em" }}
			paddingRight={{ base: "1em", md: "3em", lg: "1em" }}
			bgColor='black'
			flexDir={{ base: "column", md: "column", lg: "row" }}
		>
			<Flex
				w={{ base: "100%", md: "100%", lg: "50%" }}
				h={{ base: "50vh", md: "50vh", lg: "100vh" }}
				flexDir='column'
				alignItems='center'
				justifyContent='center'
			>
				<Heading alignItems='center' justifyContent='center' color='white'>
					We Are Here to Serve
				</Heading>
				<Flex h='10vh'></Flex>
				<Flex
					w='100%'
					alignItems='center'
					justifyContent='space-between'
					bgColor='black'
					padding={{ base: "1em", md: "1em", lg: "1.25em" }}
				>
					<Flex flexDir='column'>
						<Text color='white'>Phone</Text>
						<Text color='white'>+1 408 464 7751</Text>
					</Flex>
					<Flex flexDir='column'>
						<Text color='white'>Email</Text>
						<Text color='white'>support@tinkerboost.com</Text>
					</Flex>
				</Flex>
			</Flex>
			<Flex
				w={{ base: "100%", md: "100%", lg: "50%" }}
				h={{ base: "50vh", md: "50vh", lg: "100vh" }}
				flexDir='column'
				alignItems='center'
				justifyContent='center'
				padding={{ base: "1em", md: "1em", lg: "5em" }}
			>
				<Text color='white'>
					TinkerBoost is excited to serve you. Our mission is to maximize your FBA reimbursement, and offer you the best
					in class service.
				</Text>
				<Flex color='white' letterSpacing='tight' marginTop={{ base: "2em", md: "2em", lg: "2em" }}>
					<Button
						colorScheme='white'
						variant='outline'
						borderRadius='0'
						borderWidth='0.1em'
						size={{ base: "md", md: "md", lg: "lg" }}
						as={ReactLink}
						to='/help'
					>
						How to use TinkerBoost's service
					</Button>
				</Flex>
			</Flex>
		</Flex>

		{/* Seventh part: Footer. */}
		<Flex w='100%' h='10vh' overflow='auto'>
			<Footer />
		</Flex>
	</div>
);

export default LandingScreen;
