import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	Divider,
	Spinner,
	Stack,
	TableContainer,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
	Wrap,
	Flex,
	Select,
	Icon,
} from "@chakra-ui/react";
import { setCurStatus, setCurStore, setCurClaimValueOrder, setCurDateFiledOrder } from "../redux/slices/userCase.js";
import { useDispatch, useSelector } from "react-redux";
import { getUserCases, getCustomizedCases } from "../redux/actions/caseActions";
import { getPagiEvents } from "../redux/actions/eventActions.js";
import ShipTableItem from "./ShipTableItem.jsx";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const InboundShipDetail = () => {
	const dispatch = useDispatch();

	const {
		loading,
		error,
		pagiEvents,
		pagination: { totalPages, currentPage },
	} = useSelector((state) => state.event);

	const paginationButtonClick = (currentPage) => {
		dispatch(getPagiEvents(currentPage));
	};

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			alignItems='center'
			justifyContent='flex-start'
			w={{ base: "96%", md: "97%" }}
			marginTop='1.5em'
			flexDir='column'
			borderRadius='1vw'
			bgColor='white'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex alignItems='center' justifyContent='flex-start' w='100%' minH='50em' flexDir='column' borderRadius='1vw'>
					{/* Row 1 */}
					<Flex
						// bgColor='white'
						w='100%'
						h='5em'
						fontSize='1.2vw'
						fontWeight='bold'
						alignItems='center'
						paddingLeft='1.2vw'
						borderRadius='1vw'
					>
						Shipments Waiting for Documents
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						<TableContainer>
							<Table variant='simple' h='50em' overflow='auto' size={{ base: "sm", md: "md" }}>
								<Thead>
									<Tr>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Shipment Id</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Expiration Date</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Created Date</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Store</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Item Name</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Value</Th>
									</Tr>
								</Thead>
								<Tbody>
									{pagiEvents.length > 0 &&
										pagiEvents.map((shipment) => <ShipTableItem key={shipment._id} shipment={shipment} />)}
								</Tbody>
							</Table>
						</TableContainer>
					</Flex>
					{/* Row 3, Pagination buttons */}
					<Flex w='100%' alignItems='center' justifyContent='center' borderRadius='1vw'>
						<Wrap spacing='1vw' justify='center' p='1vw'>
							<Button id='casePagiLeftArrow' onClick={() => paginationButtonClick(1)} size='sm'>
								<Icon className='nav-items-icon' as={MdKeyboardArrowLeft} boxSize={3} />
							</Button>
							{Array.from(Array(totalPages), (e, i) => {
								return (
									<Button
										colorScheme={currentPage === i + 1 ? "teal" : "gray"}
										key={i}
										onClick={() => paginationButtonClick(i + 1)}
										size='sm'
									>
										{i + 1}
									</Button>
								);
							})}
							<Button id='casePagiRightArrow' onClick={() => paginationButtonClick(totalPages)} size='sm'>
								<Icon className='nav-items-icon' as={MdKeyboardArrowRight} boxSize={3} />
							</Button>
						</Wrap>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default InboundShipDetail;
