import { Td, Tr } from "@chakra-ui/react";

const CaseTableItem = ({ userCase }) => {
	return (
		<Tr fontSize={{ base: "0.5vw", md: "0.7em" }}>
			<Td>{userCase["caseID"]}</Td>
			<Td>{userCase["status"]}</Td>
			<Td>{userCase["storeName"]}</Td>
			<Td>{userCase["claimValue"]}</Td>
			<Td>{userCase["dateFiled"]}</Td>
		</Tr>
	);
};

export default CaseTableItem;
