import { Td, Tr } from "@chakra-ui/react";

const ShipTableItem = ({ shipment }) => {
	console.log(`shipment ${shipment}`);

	return (
		<Tr fontSize='1vw'>
			<Td>{shipment.shipId}</Td>
			<Td>{shipment["expiredDate"]}</Td>
			<Td>{shipment["createdDate"]}</Td>
			<Td>{shipment["store"]}</Td>
			<Td>{shipment["itemName"]}</Td>
			<Td>{shipment["value"]}</Td>
		</Tr>
	);
};

export default ShipTableItem;
