import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const ClientBilling = () => {
	return (
		<Flex w='85vw' h='100vh' alignItems='center' justifyContent='center'>
			<Text textAlign='center'>You don't have billing statements currently</Text>
		</Flex>
	);
};

export default ClientBilling;
