import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Container,
	FormControl,
	HStack,
	Heading,
	Stack,
	Text,
	useBreakpointValue,
	useToast,
	Icon,
	Flex,
} from "@chakra-ui/react";
// import { useGoogleLogin } from "@react-oauth/google";
import { Formik } from "formik";
import { useEffect } from "react";
// import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import PasswordField from "../components/PasswordField";
import TextField from "../components/TextField";
import { googleLogin, register } from "../redux/actions/userActions";
import { setShowLogin, setShowSignup, stateReset } from "../redux/slices/user";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";

const Signup = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const redirect = "/portal";
	const toast = useToast();
	const { loading, error, userInfo } = useSelector((state) => state.user);
	const boxBR = useBreakpointValue({ base: "transparent", md: "bg-surface" });

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
			toast({
				description: userInfo.firstLogin ? "Account created. Welcome aboard." : `Welcome back ${userInfo.name}`,
				status: "success",
				isClosable: true,
			});
		}
	}, [userInfo, redirect, error, navigate, toast]);

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: async (response) => {
			const userInfo = await axios
				.get("https://www.googleapis.com/oauth2/v3/userinfo", {
					headers: { Authorization: `Bearer ${response.access_token}` },
				})
				.then((res) => res.data);
			const { sub, email, name, picture } = userInfo;
			dispatch(googleLogin(sub, email, name, picture));
		},
	});

	return (
		<Formik
			initialValues={{ email: "", password: "", name: "" }}
			validationSchema={Yup.object({
				name: Yup.string().required("A name is required."),
				email: Yup.string().email("Invalid email").required("This email address is required."),
				storeName: Yup.string().required("This store name is required."),
				password: Yup.string()
					.min(1, "Password is too short - must contain at least 1 character.")
					.required("Password is required."),
				confirmPassword: Yup.string()
					.min(1, "Password is too short - must contain at least 1 character.")
					.required("Password is required.")
					.oneOf([Yup.ref("password"), null], "Passwords must match"),
			})}
			onSubmit={(values) => {
				dispatch(stateReset());
				dispatch(register(values.name, values.email, values.password, values.storeName));
			}}
		>
			{(formik) => (
				<Flex w='100%' h='50em' alignItems='center' justifyContent='center'>
					<Stack spacing='6' h='100%' w='70%'>
						<Stack spacing='6'>
							<Stack spacing={{ base: "2", md: "3" }} textAlign='center'>
								<Heading fontSize={{ base: "xl", lg: "xl" }}>Sign up</Heading>
								<HStack spacing='1' justify='center'>
									<Text color='muted' fontSize={{ base: "0.8em", lg: "0.8em" }}>
										Already a user?
									</Text>
									<Button
										variant='link'
										color='rgba(79, 209, 197, 1)'
										size={{ base: "sm", lg: "sm" }}
										onClick={() => {
											dispatch(stateReset());
											dispatch(setShowLogin(true));
										}}
									>
										Sign in
									</Button>
								</HStack>
							</Stack>
						</Stack>
						<Box
							py={{ base: "0", md: "2" }}
							px={{ base: "4", md: "10" }}
							bg={{ boxBR }}
							// boxShadow={{ base: "none", md: "xl" }}
						>
							<Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
								<Stack spacing='5'>
									<FormControl>
										<TextField type='text' name='name' placeholder='Your first and last name.' label='Full name' />
										<TextField type='text' name='email' placeholder='you@example.com' label='Email' />
										<TextField type='text' name='storeName' placeholder='your Amazon store name' label='Store name' />
										<PasswordField type='password' name='password' placeholder='Your password' label='Password' />
										<PasswordField
											type='password'
											name='confirmPassword'
											placeholder='Confirm your new password'
											label='Confirm your password'
										/>
									</FormControl>
								</Stack>
								<Stack spacing='6'>
									<Button
										color='white'
										bgColor='rgba(79, 209, 197, 1)'
										size='lg'
										fontSize='md'
										isLoading={loading}
										type='submit'
									>
										Sign up
									</Button>
									<Button
										bgColor='rgba(79, 209, 197, 1)'
										color='white'
										size='lg'
										fontSize='md'
										isLoading={loading}
										onClick={() => handleGoogleLogin()}
									>
										<Icon as={FcGoogle} boxSize='1em' marginRight='1em' /> Sign up with Google
									</Button>
								</Stack>
							</Stack>
						</Box>
					</Stack>
				</Flex>
			)}
		</Formik>
	);
};

export default Signup;
