import "../Styles/globals.css";
import { Flex, useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link as ReactLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ClientInboundShipment from "../components/ClientInboundShipment";
import ClientHeader from "../components/ClientHeader";
import { setCurNavTab } from "../redux/slices/user";
import { getShipWaitDoc } from "../redux/actions/eventActions.js";

const PortalInboundScreen = (req, res) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useToast();
	const { userInfo } = useSelector((state) => state.user);
	// const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (userInfo === null) {
			navigate("/portal");
		} else {
			dispatch(setCurNavTab("nav-inbound"));
			dispatch(getShipWaitDoc(userInfo._id));
		}
	}, [dispatch, navigate, userInfo]);

	return (
		// The viewport flexbox.'
		<Flex h='100vh' w='100vw' overflow='hidden' bgColor='rgba(242, 242, 242, 0.9)'>
			{/* Column 1 (Nav Bar) */}
			<ClientHeader direction='column' />
			{/* Column 2 */}
			<ClientInboundShipment />
		</Flex>
	);
};

export default PortalInboundScreen;
