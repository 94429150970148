import axios from "axios";
import { setStripeIntentSecret, setStripeCustomerSessionClientSecret, setStripeCreditCardInfo, setStripeCustomerId, setFinantialInfoLoading } from '../slices/user.js';

export const getFinancialInfo = (name, email, stripeCustomerId) => async (dispatch, getState) => {
    try {
        const config = { headers: { 'Content-Type': 'application/json' }, params: { name, email, stripeCustomerId } };
        const { data } = await axios.get('/api/checkout/secret', config);
        dispatch(setStripeIntentSecret(data.clientSecret));
        dispatch(setStripeCustomerSessionClientSecret(data.customerSessionClientSecret));
        dispatch(setStripeCreditCardInfo(data.paymentMethod));
        if (data && stripeCustomerId === '') {
            dispatch(setStripeCustomerId(data.customerId));
        }
        dispatch(setFinantialInfoLoading(false));
    } catch (error) {
        console.log(error.response && error.response.data)
    }
}