import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Divider,
	Spinner,
	Stack,
	TableContainer,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
	Wrap,
	Flex,
	Heading,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	ModalFooter,
	useDisclosure,
	Select,
	Icon,
	Text,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShipTableItem from "./ShipTableItem.jsx";
import { ImAmazon } from "react-icons/im";

const ClientStoreDetail = () => {
	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = useRef(null);
	const finalRef = useRef(null);

	const {
		loading,
		error,
		pagiEvents,
		pagination: { totalPages, currentPage },
	} = useSelector((state) => state.event);

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			w='85vw'
			h={{ base: "60vh", md: "60vh", lg: "60vh" }}
			marginTop={{ base: "1em", md: "1em", lg: "1em" }}
			borderRadius='1em'
			bgColor='white'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex alignItems='center' justifyContent='flex-start' w='100%' minH='50em' flexDir='column' borderRadius='1vw'>
					{/* Row 1 */}
					<Flex
						// bgColor='white'
						w='95%'
						h='5em'
						fontSize='1.2vw'
						fontWeight='bold'
						alignItems='center'
						borderRadius='1vw'
						justifyContent='space-between'
						paddingLeft='0.5em'
						paddingRight='0.5em'
					>
						<Heading fontSize={{ base: "1em", md: "1em", lg: "1em" }}>Store Detail</Heading>
						{/* <Button onClick={onOpen} size={{ base: "3em", md: "3em", lg: "3em" }}>
							Add New Store
						</Button> */}
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						<TableContainer>
							<Table variant='simple' h='20vh' overflow='auto'>
								<Thead>
									<Tr>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Store Name</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Marketplace</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Currency</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Seller ID</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>API Token</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>API Token Status</Th>
									</Tr>
								</Thead>
								<Tbody>
									{/* {pagiEvents.length > 0 &&
										pagiEvents.map((shipment) => <ShipTableItem key={shipment._id} shipment={shipment} />)} */}
								</Tbody>
							</Table>
						</TableContainer>
					</Flex>
				</Flex>
			)}

			{/* <Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
				scrollBehavior='inside'
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add New Store</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl isRequired>
							<FormLabel>Store Name</FormLabel>
							<Input ref={initialRef} placeholder='Store name' />
						</FormControl>

						<FormControl isRequired mt={4}>
							<FormLabel>Marketplace</FormLabel>
							<Select placeholder='Select Marketplace'>
								<optgroup label='Americas'>
									<option value='option1'>United States</option>
									<option value='option2'>Canada</option>
									<option value='option3'>Mexico</option>
									<option value='option3'>Brazil</option>
								</optgroup>

								<optgroup label='Europe'>
									<option value='option2'>Germany</option>
									<option value='option3'>Spain</option>
									<option value='option3'>France</option>
									<option value='option3'>Italy</option>
									<option value='option3'>Netherlands</option>
									<option value='option3'>Poland</option>
									<option value='option3'>Sweden</option>
									<option value='option1'>United Kingdom</option>
									<option value='option1'>Turkey</option>
								</optgroup>

								<optgroup label='Asia-Pacific'>
									<option value='option2'>Australia</option>
									<option value='option1'>Japan</option>
									<option value='option3'>India</option>
									<option value='option3'>Singapore</option>
								</optgroup>

								<optgroup label='Middle East'>
									<option value='option2'>United Arab Emirates</option>
									<option value='option1'>Egypt</option>
									<option value='option3'>Saudi Arabia</option>
								</optgroup>
							</Select>
						</FormControl>

						<FormControl isRequired mt={4}>
							<FormLabel>Currency</FormLabel>
							<Select placeholder='Select Currency'>
								<option value='option1'>U.S. Dollar </option>
								<option value='option2'>Canadian Dollar</option>
								<option value='option3'>Mexican Peso</option>
								<option value='option1'>Pound Sterling (British) </option>
								<option value='option1'>Euro</option>
								<option value='option1'>Australian Dollar</option>
								<option value='option1'>Japanese Yen</option>
							</Select>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Type of Seller</FormLabel>
							<Select placeholder='Select Type of Seller'>
								<option value='option1'>Reseller</option>
								<option value='option2'>Private Label</option>
								<option value='option3'>Manufacturer</option>
								<option value='option3'>Brand Owner</option>
							</Select>
						</FormControl>
					</ModalBody>

					<ModalFooter alignItems='center' justifyContent='center'>
						<Button margin='1em' paddingLeft='1em' paddingRight='1em'>
							<Icon as={ImAmazon} marginRight='1em' />
							<Text fontSize={{ base: "1em", md: "1em", lg: "0.7em" }}>Authorize TinkerBoost</Text>
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal> */}
		</Flex>
	);
};

export default ClientStoreDetail;
