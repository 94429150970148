import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    shipNeedDoc: [],
    workingEvents: [],
    pagiEvents: [],
    pagination: {
        'eventsPerPage': 10,
        'currentPage': 1,
        'totalPages': 0
    },
};

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEventError: (state, { payload }) => {
            state.error = payload;
        },
        setShipNeedDoc: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.shipNeedDoc = payload;
            state.workingEvents = state.shipNeedDoc;
        },
        setPagination: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.pagination = payload;
        },
        setPagiEvents: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.pagiEvents = payload;
        },
        setWorkingEvents: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.workingEvents = payload;
        },
    },
});

// The followings are actually generated action creators, not actions themselves.
// Action creators and action types are generated for each case reducer function.
export const {
    setEventError,
    setShipNeedDoc,
    setPagiEvents,
    setPagination
} = eventSlice.actions;

export default eventSlice.reducer;

export const eventSelector = (state) => state.event;